<template>
  <div>

    <div v-if="progressStore.progress !== true">

      <!--Title Bar-->
      <title-bar :title-value="clientsStore.client.d_nameLast + ', ' + clientsStore.client.d_nameFirst" />

      <!--Function Bar-->
      <client-function-bar />

      <v-container fluid class="pa-0">
        <v-row dense>

          <!--Client Applications-->
          <v-col cols="12" sm="12">
            <v-card flat>
              <v-toolbar dense flat color="secondary" dark>
                <v-icon>fal fa-sliders-h-square</v-icon>
                <v-toolbar-title class="pl-2">Preferences</v-toolbar-title>

                <v-spacer />
                <client-entry-preferences />
              </v-toolbar>
            </v-card>

            <v-card flat class="blue-grey lighten-5" height="100%">
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" sm="12" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12" md="6">
                        <text-display
                          :displayLabel="'Opt-In to Texting Program'"
                          :displayString="$_yesNo(clientsStore.client.household.f_textingOptIn)" />
                      </v-col>
                    </v-row>

                    <v-row dense v-if="clientsStore.client.household.f_textingOptIn">
                      <v-col cols="12" sm="12" md="6">
                        <text-display :displayLabel="'Cellphone Owner'" :displayString="clientsStore.client.household.d_textingName" />
                      </v-col>
                    </v-row>

                    <v-row dense v-if="clientsStore.client.household.f_textingOptIn">
                      <v-col cols="12" sm="12" md="6">
                        <text-display :displayLabel="'Cellphone'" :displayString="clientsStore.client.household.d_textingPhone" />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-container>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import ClientFunctionBar from '@/components/ClientFunctionBar';
import TextDisplay from '@/components/TextDisplay';
import ClientEntryPreferences from '@/components/ClientEntryPreferences';

export default {
  name: 'ClientPreferences',
  mixins: [mixin],
  computed: {
    ...mapState({
      clientsStore: (state) => state.clientsStore,
      progressStore: (state) => state.progressStore,
    }),
  },
  components: {
	  TitleBar,
    ClientFunctionBar,
    TextDisplay,
    ClientEntryPreferences,
  },
  created() {
    this.initialize();
  },
  methods: {
	  async initialize() {
      this.$store.dispatch('progressStore/set', true);
      await this.$store.dispatch('clientsStore/read', this.$route.params.clientUUID);
      this.$store.dispatch('progressStore/set', false);
	  },

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
