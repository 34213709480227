<template>
  <div>
    <v-btn rounded outlined @click="openEntry()"
      ><v-icon small left>fal fa-pencil-alt</v-icon>Edit</v-btn
    >

    <!--Record Entry-->
    <v-dialog v-model="show2" persistent max-width="800" max-height="400">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Client Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="12" md="6">
                  <v-row dense>
                    <v-col cols="12" sm="12" md="6">
                      <v-select
                        :items="valueListsStore.yesNo"
                        item-text="value"
                        item-value="id"
                        label="Opt-in to Texting Program"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_textingOptIn"
                        @input="f_textingOptIn = $event || 0"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense v-if="f_textingOptIn">
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="d_textingPhone"
                        label="Contact Cell Number"
                        placeholder=" "
                        persistent-placeholder
                        :blur="d_textingPhone = $_formatPhone(d_textingPhone)"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense v-if="f_textingOptIn">
                    <v-col cols="12" sm="12"  md="6">
                      <v-text-field
                        v-model="d_textingName"
                        label="Contact Name"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";

import { mixin } from "@/mixins/mixin.js";

const { mapFields } = createHelpers({
  getterType: "clientsStore/getEntryField",
  mutationType: "clientsStore/updateEntryField"
});

export default {
  name: "ClientEdit",
  mixins: [mixin],
  computed: {
    ...mapState({
      clientsStore: state => state.clientsStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "show2",
      "valid",
      "section",
      "mode",
      "id",

      "fk_householdID",
      "fk_userID",
      "d_childIDv1",
      "d_nameLast",
      "d_nameMiddle",
      "d_nameFirst",
      "d_dateBirth",
      "d_childNumber",
      "d_prekSeatID",
      "d_gender",
      "d_race",
      "d_raceOther",
      "d_weeklyHoursCare",
      "d_verifiedDateOfBirth",
      "d_additionalInfoHealthInsurance",
      "d_additionalInfoUSCitizen",
      "d_additionalInfoSpecialNeed",
      "d_additionalInfoSpecialNeedDescribe",
      "d_additionalInfoReceivingServices",
      "d_dateServicePlanEnd",
      "d_shareFinancial",
      "d_shareFinancialName",
      "d_shareFinancialRelationship",
      "d_shareFinancialDescribe",
      "d_language",
      "d_dateCertificationStart",
      "d_dateCertificationEnd",
      "d_dateTerm",
      "d_termReason",
      "d_referralSource",
      "d_referralDate",
      "d_referralName",
      "d_referralEmail",
      "d_referralPhone",
      "d_priorityService",
      "d_priorityPickup",
      "f_textingOptIn",
      "d_textingPhone",
      "d_textingName",
      "f_privateRecord",
      "date1",
      "date2",
      "date3",
      "date4",
      "date5",
      "date6"
    ])
  },
  data() {
    return {
      menu: false
    };
  },
  watch: {
    date1() {
      this.d_dateBirth = this.$_formatDate(this.date1);
    },
    date2() {
      this.d_dateCertificationStart = this.$_formatDate(this.date2);
    },
    date3() {
      this.d_dateCertificationEnd = this.$_formatDate(this.date3);
    },
    date4() {
      this.d_dateTerm = this.$_formatDate(this.date4);
    },
    date5() {
      this.d_referralDate = this.$_formatDate(this.date5);
    },
    date6() {
      this.d_dateServicePlanEnd = this.$_formatDate(this.date6);
    }
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("clientsStore/resetEntry");
    },

    async openEntry() {
      this.resetEntry();

      const client = this.$store.getters["clientsStore/getClient"];

      await this.$store.dispatch("clientsStore/entry", client.uuid);

      this.mode = 2;
      this.show2 = true;
    },

    cancelEntry() {
      this.show2 = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["clientsStore/getEntry"];
        await this.$store.dispatch("clientsStore/update", entry);
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
